<template>
  <div>
    <CRow>
      <CCol lg="6">
        <CCardBody>
          <CDataTable
                  :items="apiDatas"
                  :fields="fields"
                  column-filter
                  table-filter
                  items-per-page-select
                  :items-per-page="50"
                  sorter
                  pagination
                  clickable-rows
          >
            <template #actions="{ item }">
              <td>
<!--                <CButton @click="removeTime(item)" color="primary">Удалить</CButton>-->
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCol>
      <CCol lg="6">
        <CCard>
          <CCardHeader>
            <strong>Добавить Тип данных</strong>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="createGrs()">
              <CInput
                      type="hidden"
                      name="action"
                      v-model="form.action"
                      required
              />
              <CInput
                      type="text"
                      label="Введите Тип данных"
                      v-model="form.name"
                      required
              />
              <CButton type="submit" size="sm" color="primary"><CIcon name="cil-check-circle"/> Добавить</CButton>
            </CForm>
          </CCardBody>
          <CCardFooter>
          </CCardFooter>
        </CCard>
      </CCol>

    </CRow>
  </div>
</template>

<script>
  import axios from 'axios'


  const fields = [
    {
      key: "name",
      label: "Тип данных",
      _style: "min-width:100%;"
    },
    {
      key: "actions",
      label: "Действия",
      _style: "min-width:1%;",
      sorter: false,
      filter: false
    },
  ];

  export default {
    name: 'Datatypes',
    data: function() {
      return {
        visibleLiveDemo: false,
        apiDatas: [],
        regionDatas: [],
        operatorDatas: [],
        tableFields: ['id', 'name'],
        form: {
          action: 'add_datatype',
          name: ""
        },
        fields,
      }
    },
    methods: {
      getDataFromApi () {
        axios
          .get(`${axios.defaults.baseURL}?action=get_all_datatypes`)
          .then(response => (this.apiDatas = response.data))
      },
      createGrs () {

        let bodyFormData = new FormData();

        bodyFormData.append('action', this.form.action);
        bodyFormData.append('name', this.form.name);

        console.log(bodyFormData)

        axios({
          method: "post",
          url: `${axios.defaults.baseURL}`,
          data: bodyFormData
        })
          .then((res) => {
            this.form.name = "";
            //Perform Success Action
            this.getDataFromApi()
          })
          .catch((error) => {
            console.error(error)
            // error.response.status Check status code
          }).finally(() => {
          // this.getDataFromApi()
        });
      },
      removeTime (item) {

        let bodyFormData = new FormData();

        bodyFormData.append('action', "remove_datatype");
        bodyFormData.append('id', item.id);

        axios({
          method: "post",
          url: `${axios.defaults.baseURL}`,
          data: bodyFormData
        })
          .then(() => {
            //Perform Success Action
            this.getDataFromApi()
          })
          .catch((error) => {
            console.error(error)
            // error.response.status Check status code
          }).finally(() => {
          // this.getDataFromApi()
        });
      },
    },
    mounted() {
      axios
        .get(`${axios.defaults.baseURL}?action=get_all_datatypes`)
        .then(response => (this.apiDatas = response.data))
    }
  }
</script>
